import React from 'react';

const NumberFour = () => {
  return (
    <svg
      width="144"
      height="187"
      viewBox="0 0 144 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M119.487 185.295H120.487V184.295V150.445H141.392H142.392V149.445V121.296V120.296H141.392H120.487L120.487 77.8671V76.8671H119.487H91.172H90.2656L90.1768 77.7692L85.9917 120.296H40.3217L111.528 2.51743L112.445 1.00006H110.672L72.2064 1.00006H71.6285L71.34 1.50074L1.35337 122.942L1.21979 123.173V123.441L1.21979 149.445V150.445H2.21979H86.1653V184.295V185.295H87.1653H119.487Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
};

export default NumberFour;
